import {Grid, Typography} from '@material-ui/core'
import {GridSize} from '@material-ui/core/Grid/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {useTenant} from 'contexts/tenant'
import {useMobile} from 'core/hooks/use-mobile'
import Head from 'next/head'
import React, {FC} from 'react'
import * as settings from 'settings'

export interface PageHeaderProps {
    title: string
    titleData?: string | number
    subtitle?: string | JSX.Element | JSX.Element[]
    subtitle2?: string | JSX.Element | JSX.Element[]
    gridSpacings?: [GridSize, GridSize]
}

export const PageHeader: FC<PageHeaderProps> = ({
    title,
    titleData,
    subtitle, subtitle2,
    children,
    gridSpacings = [12, 12],
}) => {
    const isMobile = useMobile()
    const classes = useStyles()
    const {tenant} = useTenant()

    return (
        <div className={classes.root}>
            <Head>
                <title>
                    {title} / {settings.SITE_NAME} ({tenant?.name})
                </title>
            </Head>
            {isMobile
                ? (
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={gridSpacings[0]}>
                            {titleData
                                ? <Typography component='h1' variant='h1'>{title} #{titleData}</Typography>
                                : <Typography component='h1' variant='h1'>{title}</Typography>
                            }
                        </Grid>
                        <Grid item={true} xs={gridSpacings[1]}>
                            {children}
                        </Grid>
                    </Grid>
                )
                : (
                    <div className={classes.title}>
                        <Grid container={true} className={classes.grid}>
                            {titleData
                                ? <Typography component='h1' variant='h1'>{title} #{titleData}</Typography>
                                : <Typography component='h1' variant='h1'>{title}</Typography>
                            }
                            <div className={classes.children}>
                                {children}
                            </div>
                        </Grid>
                        <Grid item={true} xs={12}>
                            {subtitle && <Typography component='p' variant='subtitle1'>{subtitle}</Typography>}
                            {subtitle2 && <Typography component='p' variant='subtitle1'>{subtitle2}</Typography>}
                        </Grid>
                    </div>
                )
            }
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    title: {
        width: '100%',
        '& p': {
            marginTop: theme.spacing(1),
        },
    },
    children: {
        textAlign: 'right',
        minWidth: theme.spacing(40),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        '@media print': {
            display: 'none',
        },
    },
    grid: {
        justifyContent: 'space-between',
    },
}))
