import {ApolloError} from '@apollo/client'
import {CircularProgress} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {GraphQLErrors} from 'components/graphql-errors'
import React, {FC} from 'react'

interface PageProps {
    loading?: boolean
    error?: ApolloError
    refetch?: () => any
}

export const Page: FC<PageProps> = ({loading, error, refetch, children}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {loading && <CircularProgress color='primary' />}
            {error && <GraphQLErrors error={error} refetch={refetch} />}
            {!loading && children}
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& > * + *': {
            // Danger! This might generate issues in some pages...
            marginTop: `${theme.spacing(2.5)}px !important`,
            [theme.breakpoints.down('sm')]: {
                marginTop: `${theme.spacing(1)}px !important`,
            },
        },
        '@media screen': {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(3),
            },
        },
    },
}))
