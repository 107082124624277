import {t} from '@lingui/macro'
import {Alert, AlertTitle} from '@material-ui/lab'
import * as Sentry from '@sentry/node'
import {Page} from 'components/layout/page'
import {PageHeader} from 'components/page-header'
import {NextPage, NextPageContext} from 'next'

interface ErrorPageProps {
    statusCode: number
    getInitialPropsHasRun?: boolean
    err?: Error | null
}

const ErrorPage: NextPage<ErrorPageProps> = ({statusCode, getInitialPropsHasRun, err}) => {
    if (!getInitialPropsHasRun && err) {
        Sentry.captureException(err)
    }

    let title = t`Internal server error`
    let description = t`The request cannot be processed now.`

    if (statusCode === 400) {
        title = t`Bad request`
        description = t`The submitted request contains errors and cannot be processed.`
    }

    if (statusCode === 401) {
        title = t`Authentication required`
        description = t`The requested page requires authentication.`
    }

    if (statusCode === 403) {
        title = t`Permission denied`
        description = t`Unable to view the requested page due to insufficient permissions.`
    }

    if (statusCode === 404) {
        title = t`Page not found`
        description = t`The page you are looking for does not exist.`
    }

    return (
        <Page>
            <PageHeader title={t`Error`} />
            <Alert severity='error'>
                <AlertTitle>{title}</AlertTitle>
                {description}
            </Alert>
        </Page>
    )
}

ErrorPage.getInitialProps = async ({res, err, asPath}: NextPageContext) => {
    // Do not capture an exception in Sentry for 404s.
    if (res?.statusCode === 404) {
        return {statusCode: 404}
    }

    const props = {
        err,
        statusCode: res?.statusCode || err?.statusCode || 404,
        getInitialPropsHasRun: true, // Workaround for https://github.com/vercel/next.js/issues/8592
    }

    if (err) {
        Sentry.captureException(err)
        await Sentry.flush(2000)
        return props
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry.
    Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
    await Sentry.flush(2000)

    return props
}

export default ErrorPage
