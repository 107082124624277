import {CircularProgress} from '@material-ui/core'
import {TenantFragment, useGetActiveTenantQuery} from 'generated/graphql'
import ErrorPage from 'pages/_error'
import {createContext, FC, useContext} from 'react'

type TenantContextType = {
    tenant: TenantFragment | undefined
}

const TenantContext = createContext<TenantContextType>({
    tenant: undefined,
})

export const TenantProvider: FC = ({children}) => {
    const {data, loading} = useGetActiveTenantQuery({fetchPolicy: 'cache-first'})

    return (
        <TenantContext.Provider value={{tenant: data?.activeTenant}}>
            {loading
                ? <CircularProgress color='primary' />
                : data?.activeTenant ? children : <ErrorPage statusCode={404} />
            }
        </TenantContext.Provider>
    )
}

export const useTenant = () => useContext(TenantContext)
